/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './StudentsUIHelper';

const StudentsUIContext = createContext();

export function useStudentsUIContext() {
  return useContext(StudentsUIContext);
}

export const StudentsUIConsumer = StudentsUIContext.Consumer;

export function StudentsUIProvider({ currentUserId, children }) {
  const [userId, setUserId] = useState(currentUserId);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const [selectedId, setSelectedId] = useState(null);
  const initUser = {
    _id: undefined,
    email: '',
    title: '',
    firstName: '',
    lastName: '',
    gender: '',
    personalId: '',
    role: '',
    status: '',
    avatar: '',
    degreeProgram: undefined,
    birthDate: '',
    phoneNumber: '',
    students: undefined,
    teacher: undefined,
    createdAt: '',
    updatedAt: '',
  };

  useEffect(() => {
    initUser.teacher = currentUserId;
    setUserId(currentUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const [showAppointDialog, setShowAppointDialog] = useState(false);
  const openAppointDialog = () => {
    setSelectedId(undefined);
    setShowAppointDialog(true);
  };

  const closeAppointDialog = () => {
    setSelectedId(undefined);
    setShowAppointDialog(false);
  };

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const openDeleteDialog = (id) => {
    setSelectedId(id);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => {
    setSelectedId(undefined);
    setShowDeleteDialog(false);
  };

  const value = {
    ids,
    setIds,
    userId,
    setUserId,
    queryParams,
    initUser,
    selectedId,
    showAppointDialog,
    openAppointDialog,
    closeAppointDialog,
    showDeleteDialog,
    openDeleteDialog,
    closeDeleteDialog,
  };

  return (
    <StudentsUIContext.Provider value={value}>
      {children}
    </StudentsUIContext.Provider>
  );
}
