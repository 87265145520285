import { createSlice } from "@reduxjs/toolkit";

const initialHashtagsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    hashtags: null,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const hashtagsSlice = createSlice({
    name: "hashtags",
    initialState: initialHashtagsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },


        hashtagsFetched: (state, action) => {
            const { totalCount, hashtags } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.hashtags = hashtags || [];
            state.totalCount = totalCount;
        },
    },
});
