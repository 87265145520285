import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import * as actions from "../../_redux/users/usersActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { UserNewPasswordForm } from "./UserNewPasswordForm";
import { useUsersUIContext } from "../UsersUIContext";

export default function UserNewPasswordDialog({ id, show, onHide }) {
  // State
  const [url, setUrl] = useState("");

  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      initUser: usersUIContext.initUser,
    };
  }, [usersUIContext]);

  // User Redux
  const dispatch = useDispatch();
  const { actionsLoading, userForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      userForEdit: state.users.userForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchUser(id));
  }, [id, dispatch]);

  const requestPassword = (userId) => {
    dispatch(actions.forgetPassword(userId)).then((url) => setUrl(url));
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {actionsLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          คำขอลิ้งค์เปลี่ยนรหัสผ่าน
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}

        <UserNewPasswordForm
          requestPassword={requestPassword}
          url={url}
          user={userForEdit || usersUIProps.initUser}
        />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            ยกเลิก
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
