import React, { useMemo } from 'react';
import { useStudentsUIContext } from './StudentsUIContext';
import { StudentLoadingDialog } from './StudentLoadingDialog';
import { StudentsTable } from './StudentsTable';
import { StudentsFilter } from './StudentsFilter';
import { StudentAppointDialog } from './student-appoint-dialog/StudentAppointDialog';
import { ToastContainer } from 'react-toastify';
import { StudentDeleteDialog } from './StudentDeleteDialog';

export default function Students() {
  const studentsUIContext = useStudentsUIContext();
  // eslint-disable-next-line no-unused-vars
  const studentsUIProps = useMemo(() => {
    return { ids: studentsUIContext.ids };
  }, [studentsUIContext]);

  return (
    <>
      <StudentLoadingDialog />
      <StudentAppointDialog />
      <StudentDeleteDialog />
      <div className="form margin-b-30">
        <StudentsFilter />
        {/* {teacherUIProps.ids.length > 0 && <RemarksGrouping />} */}
      </div>
      <StudentsTable />
      <ToastContainer />
    </>
  );
}
