import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function SentimentComponent({ storySentiment, summarizeSentiment }) {
  const sentimentToText = (polarity) => {
    switch (polarity) {
      case 'positive':
        return <i className="fas fa-chevron-circle-up text-success"></i>;
      case 'negative':
        return <i className="fas fa-chevron-circle-down text-danger"></i>;
      default:
        return <i class="fas fa-minus-circle"></i>;
    }
  };

  const renderStoryTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      เรื่องราวที่ได้พบเจอ
    </Tooltip>
  );

  const renderSumTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      สื่งที่ได้เรียนรู้
    </Tooltip>
  );

  if (!storySentiment || !summarizeSentiment) {
    return <span className="text-warning">ยังไม่มีข้อมูล</span>;
  }

  return (
    <React.Fragment>
      {storySentiment && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderStoryTooltip}
        >
          {sentimentToText(storySentiment.polarity)}
        </OverlayTrigger>
      )}{' '}
      {summarizeSentiment && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderSumTooltip}
        >
          {sentimentToText(summarizeSentiment.polarity)}
        </OverlayTrigger>
      )}
    </React.Fragment>
  );
}

export default SentimentComponent;
