import React from 'react';
import { HashtagsCard } from './page/HashtagCard';
import { HashtagsUIProvider } from './page/HashtagsUIContext';

export function Hashtags() {
    return (
        <HashtagsUIProvider>
            <HashtagsCard />
        </HashtagsUIProvider>
    );
}