import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { HashtagsPage } from './pages/HashtagsPage';
import { DashboardPage } from './pages/DashboardPage';
import UsersIndexPage from './modules/Users/usersIndexPage';

const DiariesManagePage = lazy(() =>
  import('./modules/Diary/pages/DiariesManagePage')
);
const NewsManagePage = lazy(() =>
  import('./modules/News/pages/NewsManagePage')
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/hashtags" component={HashtagsPage} />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/diary-management" component={DiariesManagePage} />
        <Route path="/news-management" component={NewsManagePage} />
        <Route path="/user-management" component={UsersIndexPage} />
        <Redirect to="error/error-v5" />
      </Switch>
    </Suspense>
  );
}
