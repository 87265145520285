/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { Dropdown } from 'react-bootstrap';

export function HashtagWidget({ className }) {
  const intl = useIntl();
  const [hashtag, setHashtag] = useState([]);
  const [value, setValue] = useState('weekly');
  const numeral = require('numeral'); // numeral lybrary

  useEffect(() => {
    axios
      .get(`stat/hashtag?frequency=${value}&limit=8`) // you can set the limit
      .then((response) => {
        const hashtag = response.data.data;
        setHashtag(hashtag);
      })
      .catch((error) => {
        console.log('Fetching hashtag has error =>', error);
      });
  }, [value]);

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            {intl.formatMessage({ id: 'DASHBOARD.WIDGET.HASHTAG.TITLE' })}
          </h3>
          <div className="card-toolbar">
            <Dropdown className="dropdown-inline" drop="down" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top2"
                variant="transparent"
                className="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle"
              >
                {intl.formatMessage({
                  id: 'DASHBOARD.WIDGET.HASHTAG.DROPDOWN.VIEW',
                })}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                {/*begin::Navigation*/}
                <ul className="navi navi-hover">
                  <li className="navi-header font-weight-bold py-5">
                    <span className="font-size-lg">
                      {intl.formatMessage({
                        id: 'DASHBOARD.WIDGET.HASHTAG.DROPDOWN.TITLE',
                      })}
                    </span>
                    <i
                      className="flaticon2-information icon-md text-muted"
                      data-toggle="tooltip"
                      data-placement="right"
                      title={intl.formatMessage({
                        id: 'DASHBOARD.WIDGET.HASHTAG.DROPDOWN.INFO',
                      })}
                    ></i>
                  </li>
                  <li className="navi-separator mb-3 opacity-70"></li>
                  <li className="navi-item">
                    <a className="navi-link" onClick={() => setValue('weekly')}>
                      <span className="navi-text">
                        <span className="label label-xl label-inline label-light-success">
                          {intl.formatMessage({
                            id: 'DASHBOARD.WIDGET.HASHTAG.DROPDOWN.WEEKLY',
                          })}
                        </span>
                      </span>
                    </a>
                  </li>
                  <li className="navi-item">
                    <a
                      className="navi-link"
                      onClick={() => setValue('monthly')}
                    >
                      <span className="navi-text">
                        <span className="label label-xl label-inline label-light-danger">
                          {intl.formatMessage({
                            id: 'DASHBOARD.WIDGET.HASHTAG.DROPDOWN.MONTHLY',
                          })}
                        </span>
                      </span>
                    </a>
                  </li>
                  <li className="navi-item">
                    <a className="navi-link" onClick={() => setValue('yearly')}>
                      <span className="navi-text">
                        <span className="label label-xl label-inline label-light-warning">
                          {intl.formatMessage({
                            id: 'DASHBOARD.WIDGET.HASHTAG.DROPDOWN.YEARLY',
                          })}
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
                {/*end::Navigation*/}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-2">
          <table className="table table-borderless table-vertical-center">
            <tbody>
              <tr>
                <td>
                  <div>
                    <span className="navi-text">
                      <span
                        className={`label label-xl label-inline label-light-${
                          value === 'weekly'
                            ? 'success'
                            : value === 'monthly'
                            ? 'danger'
                            : 'warning'
                        }`}
                      >
                        {intl.formatMessage({
                          id: `DASHBOARD.WIDGET.HASHTAG.DROPDOWN.${value.toUpperCase()}`,
                        })}
                      </span>
                    </span>
                  </div>
                </td>
              </tr>

              {hashtag ? (
                hashtag.map((data, index) => (
                  <tr key={data._id}>
                    <td className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="svg-icon svg-icon-lg svg-icon-primary">
                          {intl.formatMessage({
                            id: `DASHBOARD.WIDGET.HASHTAG.NUMBER`,
                          })}
                          {index + 1}
                        </span>
                      </div>

                      <div className="d-flex flex-column font-weight-bold">
                        <p className="text-dark mb-1 font-size-lg">
                          {data._id}
                        </p>
                      </div>
                    </td>
                    <td>
                      {data.count > 999
                        ? numeral(data.count).format('0.00a')
                        : data.count}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <p>
                      {intl.formatMessage({
                        id: 'DASHBOARD.WIDGET.HASHTAG.NO_DATA',
                      })}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
