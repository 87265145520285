/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DiaryRead } from './DiaryRead';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/th';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { URL } from '../../../../config/configuration';
import SentimentComponent from './components/SentimentComponent';

export function DiaryWidget({ className }) {
  const intl = useIntl();
  const [diaries, setDiaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [currentDiary, setCurrentDiary] = useState(null);

  dayjs.extend(relativeTime);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/diary`, {
        params: {
          filter: {},
          sortOrder: 'desc',
          sortField: 'createdAt',
          pageNumber: '1',
          pageSize: '5',
        },
      })
      .then((response) => {
        const data = response.data.data;
        setDiaries(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Fetching diary has error', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            {intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY.TITLE' })}
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            {intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY.SUB_TITLE' })}
          </span>
        </h3>
        <div className="card-toolbar">
          <Link to="/diary-management">
            <button className="btn btn-primary font-weight-bolder font-size-sm mr-3">
              {intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY.BUTTON' })}
            </button>
          </Link>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <tbody>
                {!loading ? (
                  diaries && diaries.length ? (
                    diaries.map((data) => (
                      <tr key={data._id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50 symbol-light-primary mr-4">
                              <img
                                className="h-75 align-self-end"
                                src={`${URL.imagePrefix}/${data.owner.avatar}`}
                                alt={`${data.owner.firstName} ${data.owner.lastName}`}
                              />
                            </div>
                            <div>
                              <p className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                                {`${data.owner.firstName} ${data.owner.lastName}`}
                              </p>
                              <span className="text-muted font-weight-bold d-block">
                                {data.owner.personalId}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 d-block font-size-lg">
                            {data.title}
                          </span>
                          <span className="text-muted font-weight-bold">
                            {intl.formatMessage({
                              id: 'DASHBOARD.WIDGET.DIARY.TOPIC_TITLE',
                            })}
                          </span>
                        </td>
                        <td>
                          <div className="text-dark-75 d-block font-size-lg">
                            <SentimentComponent
                              storySentiment={data.storySentiment}
                              summarizeSentiment={data.summarizeSentiment}
                            />
                          </div>
                          <span className="text-muted font-weight-bold">
                            {intl.formatMessage({
                              id: 'DASHBOARD.WIDGET.DIARY.SENTIMENT',
                            })}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {data.status.isRead ? (
                              <p className="label label-inline label-success mb-0">
                                {intl.formatMessage({
                                  id: 'DASHBOARD.WIDGET.DIARY.READED',
                                })}
                              </p>
                            ) : (
                              <p className="label label-inline label-danger mb-0">
                                {intl.formatMessage({
                                  id: 'DASHBOARD.WIDGET.DIARY.UNREAD',
                                })}
                              </p>
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="font-weight-bold d-block font-size-sm">
                            {dayjs(data.createdAt)
                              .locale('th')
                              .fromNow()}
                          </span>
                          <span className="text-muted font-weight-bold">
                            {intl.formatMessage({
                              id: 'DASHBOARD.WIDGET.DIARY.CREATEDAT',
                            })}
                          </span>
                        </td>
                        <td>
                          <a
                            className="btn btn-light-success font-weight-bolder font-size-sm"
                            onClick={() => {
                              setShow(!show);
                              setCurrentDiary(data._id);
                            }}
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <p>
                          {intl.formatMessage({
                            id: 'DASHBOARD.WIDGET.DIARY.ERROR',
                          })}
                        </p>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td>
                      <p>
                        {intl.formatMessage({
                          id: 'DASHBOARD.WIDGET.DIARY.LOADING',
                        })}
                      </p>
                    </td>
                  </tr>
                )}
                <DiaryRead
                  show={show}
                  diaryId={currentDiary}
                  onHide={() => {
                    setShow(false);
                    setCurrentDiary(null);
                  }}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
