import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useStudentsUIContext } from './StudentsUIContext';
import * as actions from '../../_redux/students/studentsActions';
import {
  sortCaret,
  headerSortingClasses,
  PleaseWaitMessage,
  NoRecordsFoundMessage,
  getHandlerTableChange,
} from '../../../../../_metronic/_helpers';
import * as uiHelpers from './StudentsUIHelper';
import { useIntl } from 'react-intl';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import * as columnFormatters from './column-formatter';
import { Pagination } from '../../../../../_metronic/_partials/controls';
import BootstrapTable from 'react-bootstrap-table-next';

export function StudentsTable() {
  const intl = useIntl();
  const StudentsUIContext = useStudentsUIContext();
  const studentsUIProps = useMemo(() => {
    return {
      ids: StudentsUIContext.ids,
      setIds: StudentsUIContext.setIds,
      queryParams: StudentsUIContext.queryParams,
      setQueryParams: StudentsUIContext.setQueryParams,
      userId: StudentsUIContext.userId,
      openDeleteDialog: StudentsUIContext.openDeleteDialog,
    };
  }, [StudentsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.students }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();
  useEffect(() => {
    studentsUIProps.setIds([]);
    dispatch(
      actions.fetchStudents(studentsUIProps.queryParams, studentsUIProps.userId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsUIProps.queryParams, dispatch, studentsUIProps.userId]);

  const columns = [
    {
      dataField: 'personalId',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.FIELDS.PERSONALID',
      }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'firstName',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.FIELDS.FIRSTNAME',
      }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'lastName',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.FIELDS.LASTNAME',
      }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'email',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.FIELDS.EMAIL',
      }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'degreeProgram.major',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.FIELDS.MAJOR',
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: 'gender',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.FIELDS.GENDER',
      }),
      sort: false,
      formatter: columnFormatters.GenderColumnFormatter,
      formatExtraData: {
        gender: {
          male: intl.formatMessage({
            id: 'USER_MANAGEMENT.FIELDS.GENDER.MALE',
          }),
          female: intl.formatMessage({
            id: 'USER_MANAGEMENT.FIELDS.GENDER.FEMALE',
          }),
          no_gender: intl.formatMessage({
            id: 'USER_MANAGEMENT.FIELDS.GENDER.NO_GENDER',
          }),
        },
      },
      sortCaret: sortCaret,
    },
    {
      dataField: 'action',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.ACTIONS',
      }),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openDeleteDialog: studentsUIProps.openDeleteDialog,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px',
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: studentsUIProps.queryParams.pageSize,
    page: studentsUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  studentsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
