import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from "../../../_helpers";
import axios from 'axios';
import { useIntl } from 'react-intl';

export function HeaderWidget4({ className }) {
    const intl = useIntl();
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        axios.get(`stat/news/count`)
            .then(response => {
                const news = response.data.data;
                setNews(news);
                setLoading(false);
            })
            .catch(error => {
                console.log('Fetching news has error', error);
                setLoading(false);
            })
    }, [])
    return (
        <>
            <div className={`card card-custom bg-danger ${className}`}>
                <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-white">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg")} />
                    </span>
                    <span className="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block">{!loading ? news : "X"}</span>
                    <span className="font-weight-bold text-white font-size-sm">{intl.formatMessage({ id: "DASHBOARD.HEADER.WIDGET_4" })}</span>
                </div>

            </div>
        </>
    )
}