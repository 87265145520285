import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { ContentRoute, LayoutSplashScreen } from '../../../_metronic/layout';
import { UserEdit } from './pages/user-edit/UserEdit';
import UsersPage from './pages/UsersPage';

export default function UsersIndexPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute path="/user-management/:id/edit" component={UserEdit} />

        <ContentRoute path="/user-management" component={UsersPage} />
      </Switch>
    </Suspense>
  );
}
