import React, { useState, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useUsersUIContext } from '../UsersUIContext';
import axios from 'axios';
import { useIntl } from 'react-intl';

const prepareFilter = (queryParams, values) => {
  const { degreeProgram, status, role, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  if (degreeProgram) {
    filter.degreeProgram = degreeProgram;
  }
  // Filter by status
  if (status) {
    filter.status = status;
  }
  // Filter by role
  if (role) {
    filter.role = role;
  }
  // Filter by all fields
  if (searchText) {
    filter.$expr = searchText;
  }

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function UsersFilter({ listLoading }) {
  const intl = useIntl();
  // Customers UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      queryParams: usersUIContext.queryParams,
      setQueryParams: usersUIContext.setQueryParams,
    };
  }, [usersUIContext]);
  const [degreePrograms, setDegreePrograms] = useState([]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(usersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, usersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      usersUIProps.setQueryParams(newQueryParams);
    }
  };

  useEffect(() => {
    /**
     * Get initial Props
     * Props: Degree Program
     */
    axios.get('/degreeprogram').then(({ data: { data } }) => {
      setDegreePrograms([...data]);
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          status: '', // values => All=""/active=0/inactive=1
          gender: '', // values => All=""/Male=0/Female=1/NoGender=3
          role: '', // values => All=""/teacher=0/student=1
          degreeProgram: '',
          searchText: '',
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              {/* begin:: Filed Degree Program */}
              <div className="col-sm-6 col-xl-3">
                <select
                  className="form-control"
                  name="degreeProgram"
                  placeholder={intl.formatMessage({
                    id:
                      'USER_MANAGEMENT.FILTER.FIELDS.DEGREEPROGRAM.PLACEHOLDER',
                  })}
                  onChange={(e) => {
                    setFieldValue('degreeProgram', e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.degreeProgram}
                >
                  <option value="">
                    {intl.formatMessage({ id: 'USER_MANAGEMENT.FILTER.ALL' })}
                  </option>
                  {degreePrograms.map((dp) => (
                    <option key={dp._id} value={dp._id}>
                      {dp.name + ' ' + dp.major}
                    </option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  <b>
                    {intl.formatMessage({ id: 'USER_MANAGEMENT.FILTER.BY' })}
                  </b>
                  {intl.formatMessage({
                    id: 'USER_MANAGEMENT.FILTER.FIELDS.DEGREEPROGRAM',
                  })}
                </small>
              </div>
              {/* end:: Filed Degree Program */}
              {/* begin:: Filed Status */}
              <div className="col-sm-6 col-xl-3">
                <select
                  className="form-control"
                  name="status"
                  placeholder={intl.formatMessage({
                    id: 'USER_MANAGEMENT.FILTER.FIELDS.STATUS.PLACEHOLDER',
                  })}
                  // TODO: Change this code
                  onChange={(e) => {
                    setFieldValue('status', e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="">
                    {intl.formatMessage({ id: 'USER_MANAGEMENT.FILTER.ALL' })}
                  </option>
                  <option value="active">
                    {intl.formatMessage({
                      id: 'USER_MANAGEMENT.FILTER.FIELDS.STATUS.ACTIVE',
                    })}
                  </option>
                  <option value="inactive">
                    {intl.formatMessage({
                      id: 'USER_MANAGEMENT.FILTER.FIELDS.STATUS.INACTIVE',
                    })}
                  </option>
                </select>
                <small className="form-text text-muted">
                  <b>
                    {intl.formatMessage({ id: 'USER_MANAGEMENT.FILTER.BY' })}
                  </b>
                  {intl.formatMessage({
                    id: 'USER_MANAGEMENT.FILTER.FIELDS.STATUS',
                  })}
                </small>
              </div>
              {/* end:: Filed Status */}
              {/* begin:: Filed Role */}
              <div className="col-sm-6 col-xl-3">
                <select
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: 'USER_MANAGEMENT.FILTER.FIELDS.STATUS.PLACEHOLDER',
                  })}
                  name="role"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('role', e.target.value);
                    handleSubmit();
                  }}
                  value={values.role}
                >
                  <option value="">
                    {intl.formatMessage({ id: 'USER_MANAGEMENT.FILTER.ALL' })}
                  </option>
                  <option value="teacher">
                    {intl.formatMessage({
                      id: 'USER_MANAGEMENT.FILTER.FIELDS.ROLE.TEACHER',
                    })}
                  </option>
                  <option value="student">
                    {intl.formatMessage({
                      id: 'USER_MANAGEMENT.FILTER.FIELDS.ROLE.STUDENT',
                    })}
                  </option>
                </select>
                <small className="form-text text-muted">
                  <b>
                    {intl.formatMessage({ id: 'USER_MANAGEMENT.FILTER.BY' })}
                  </b>
                  {intl.formatMessage({
                    id: 'USER_MANAGEMENT.FILTER.FIELDS.ROLE',
                  })}
                </small>
              </div>
              {/* end:: Filed Status */}
              {/* begin:: Filed searchText */}
              <div className="col-sm-6 col-xl-3">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({
                    id: 'USER_MANAGEMENT.FILTER.FIELDS.SEARCH_TEXT.PLACEHOLDER',
                  })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue('searchText', e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>
                    {intl.formatMessage({
                      id: 'USER_MANAGEMENT.FILTER.BY',
                    })}
                  </b>{' '}
                  {intl.formatMessage({
                    id: 'USER_MANAGEMENT.FILTER.FIELDS.SEARCH_TEXT',
                  })}
                </small>
              </div>
              {/* end:: Filed searchText */}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
