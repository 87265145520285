import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import { diarySlice } from '../app/modules/Diary/_redux/diary/diarySlice';
import { overviewSlice } from '../app/modules/Diary/_redux/overview/overviewSlice';
import { hashtagsSlice } from '../_metronic/_partials/hashtags/_redux/hashtagsSlice';
import { newsSlice } from '../app/modules/News/_redux/NewsSlice';
import { usersSlice } from '../app/modules/Users/_redux/users/usersSlice';
import { studentsSlice } from '../app/modules/Users/_redux/students/studentsSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  diaries: diarySlice.reducer,
  overview: overviewSlice.reducer,
  hashtags: hashtagsSlice.reducer,
  news: newsSlice.reducer,
  users: usersSlice.reducer,
  students: studentsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
