import React from "react";
import {
    HeaderWidget1,
    HeaderWidget2,
    HeaderWidget3,
    HeaderWidget4,
    DiaryMixedWidget,
    DiaryWidget,
    UsersWidget,
    HashtagWidget
} from "../widgets";
export function Demo1Dashboard() {
    return (<>
        {/* header stat widget */}
        <div className="row">
            <div className="col-xl-3 col-lg-6">
                <HeaderWidget1 className="card-stretch gutter-b" />
            </div>
            <div className="col-xl-3 col-lg-6">
                <HeaderWidget2 className="card-stretch gutter-b" />
            </div>
            <div className="col-xl-3 col-lg-6">
                <HeaderWidget3 className="card-stretch gutter-b" />
            </div>
            <div className="col-xl-3 col-lg-6">
                <HeaderWidget4 className="card-stretch gutter-b" />
            </div>
        </div>

        <div className="row">
            <div className="col-lg-4">
                <DiaryMixedWidget className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-8">
                <DiaryWidget className="card-stretch gutter-b" />
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4">
                <HashtagWidget className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-8">
                <UsersWidget className="card-stretch gutter-b" />
            </div>
        </div>
        {/* <div className="row">
            <div className="col-lg-6 col-xxl-4">
                <MixedWidget1 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-4">
                <ListsWidget9 className="card-stretch gutter-b" />
            </div>

            <div className="col-lg-6 col-xxl-4">
                <StatsWidget11 className="card-stretch card-stretch-half gutter-b" />
                <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
            </div>

            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
                <ListsWidget1 className="card-stretch gutter-b" />
            </div>
            <div className="col-xxl-8 order-2 order-xxl-1">
                <AdvanceTablesWidget2 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget3 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget4 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget8 className="card-stretch gutter-b" />
            </div>
        </div> */}

    </>);
}
