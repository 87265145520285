import { createSlice } from '@reduxjs/toolkit';

const initialNewsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  newsForEdit: null,
  lastError: null,
  searching: false,
  searchingResults: null,
  urlImage: null,
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const newsSlice = createSlice({
  name: 'news',
  initialState: initialNewsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // // getProductById
    // studentFetched: (state, action) => {
    //   state.actionsLoading = false;
    //   state.productForEdit = action.payload.productForEdit;
    //   state.error = null;
    // },
    // getStudentById
    newsIdFetched: (state, action) => {
      state.actionsLoading = false;
      state.newsForEdit = action.payload.newsForEdit;
      state.error = null;
    },
    // findStudents
    newsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    newsUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity._id === action.payload.news._id) {
          return action.payload.news;
        }
        return entity;
      });
    },

    // searchStudents: (state, action) => {
    //     state.searching = true;
    //     state.searchingResults = action.payload.searchingResults;
    // },

    // // createProduct
    // productCreated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   state.entities.push(action.payload.product);
    // },
    newsAdded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.totalCount++;
      state.entities.push(action.payload.news);
    },

    uploadImage: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.urlImage = action.payload.newsImage;
    },

    updateImage: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.newsForEdit.urlToImage = action.payload.newsImage;
    },

    // // updateProduct
    // productUpdated: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.map(entity => {
    //     if (entity.id === action.payload.product.id) {
    //       return action.payload.product;
    //     }
    //     return entity;
    //   });
    // },
    // // deleteProduct
    newsIdDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el._id !== action.payload.id
      );
    },
    // // deleteProducts
    // productsDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     el => !action.payload.ids.includes(el.id)
    //   );
    // },
    // // productsUpdateState
    // productsStatusUpdated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   const { ids, status } = action.payload;
    //   state.entities = state.entities.map(entity => {
    //     if (ids.findIndex(id => id === entity.id) > -1) {
    //       entity.status = status;
    //     }
    //     return entity;
    //   });
    // }
  },
});
