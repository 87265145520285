import { toast } from "react-toastify";
import * as requestFromServer from "./usersCrud";
import { usersSlice, callTypes } from "./usersSlice";

const { actions } = usersSlice;

// fetching users => initial fetch to data table
export const fetchUsers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findUsers(queryParams)
    .then((response) => {
      const { totalCount, data } = response.data;
      dispatch(actions.usersFetched({ totalCount, entities: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

// fetching user => initial fetch to dialog
export const fetchUser = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.userFetched({ userForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findUser(id)
    .then((response) => {
      const user = response.data.data;
      dispatch(actions.userFetched({ userForEdit: user }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// updating one user
export const updateUser = (id, user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUser(id, user)
    .then(() => {
      dispatch(actions.userUpdated({ user }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// updating userForEdit student array
export const updateAppointUser = (userId, selectedUserIds) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAppoint(userId, selectedUserIds)
    .then((response) => {
      toast.success(response.data.message + "🥳");
      dispatch(actions.userAppointUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// Request forget password url
export const forgetPassword = (userId) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return requestFromServer.forgetPassword(userId).then((response) => {
    dispatch(actions.userForgetPasswordFetched());
    return response.data.url;
  });
};
