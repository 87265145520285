/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useIntl } from 'react-intl';

export function HeaderMenu({ layoutProps }) {
    const intl = useIntl();
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">{intl.formatMessage({ id: "HEADER_MENU.HOME" })}</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/hashtags')}`}>
                <NavLink className="menu-link" to="/hashtags">
                    <span className="menu-text">{intl.formatMessage({ id: "HEADER_MENU.HASHTAGS" })}</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/user-management')}`}>
                <NavLink className="menu-link" to="/user-management">
                    <span className="menu-text">{intl.formatMessage({ id: "HEADER_MENU.USER" })}</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/diary-management')}`}>
                <NavLink className="menu-link" to="/diary-management">
                    <span className="menu-text">{intl.formatMessage({ id: "HEADER_MENU.DIARY" })}</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/news-management')}`}>
                <NavLink className="menu-link" to="/news-management">
                    <span className="menu-text">{intl.formatMessage({ id: "HEADER_MENU.NEWS" })}</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}


        </ul>
        {/*end::Header Nav*/}
    </div>;
}
