import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Media } from 'react-bootstrap';

export function UserTeacher({ id }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (id) {
      axios.get(`users/${id}`).then((response) => {
        setIsLoading(false);
        setUser(response.data.data);
      });
    } else {
      setUser(undefined);
    }
  }, [id]);

  return (
    <>
      {!isLoading && user ? (
        <Media>
          <div className="symbol symbol-100 bg-white-o-15 mr-5">
            <span className="symbol-label text-success font-weight-bold font-size-h4">
              {user.firstName}
            </span>
          </div>

          <Media.Body>
            <h5>
              {user.title}
              {user.firstName} {user.lastName}
            </h5>
            <p>
              {user.degreeProgram.name} {user.degreeProgram.major}
            </p>
            <p>{user.personalId}</p>
          </Media.Body>
        </Media>
      ) : (
        'ยังไม่มีอาจารย์ที่ปรึกษา'
      )}
    </>
  );
}
