import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls/Card.js';
import { UsersFilter } from './users-filter/UsersFilter.js';
import { UsersTable } from './users-table/UsersTable.js';
import { useUsersUIContext } from './UsersUIContext.js';

export default function UsersCard() {
  const intl = useIntl();
  const usersUIContext = useUsersUIContext();
  // eslint-disable-next-line no-unused-vars
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      openEditUserPage: usersUIContext.openEditUserPage,
      // ...
    };
  }, [usersUIContext]);

  return (
    <Card>
      {/* begin:: Card Header */}
      <CardHeader title={intl.formatMessage({ id: 'USER_MANAGEMENT.TITLE' })}>
        <CardHeaderToolbar>
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={() => console.log('New User Clicked!')}
          >
            New User
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      {/* end:: Card Header */}
      {/* begin:: Card Body */}
      <CardBody>
        <UsersFilter />
        {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <UsersTable />
      </CardBody>
      {/* end:: Card Body */}
    </Card>
  );
}
