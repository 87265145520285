import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ModalProgressBar } from '../../../../../_metronic/_partials/controls';
import { useStudentsUIContext } from './StudentsUIContext';
import * as actions from '../../_redux/students/studentsActions';
import { useIntl } from 'react-intl';

export function StudentDeleteDialog() {
  const intl = useIntl();
  // Student Context UI
  const StudentsUIContext = useStudentsUIContext();
  const studentsUIProps = useMemo(() => {
    return {
      id: StudentsUIContext.selectedId,
      queryParams: StudentsUIContext.queryParams,
      setQueryParams: StudentsUIContext.setQueryParams,
      userId: StudentsUIContext.userId,
      showDeleteDialog: StudentsUIContext.showDeleteDialog,
      closeDeleteDialog: StudentsUIContext.closeDeleteDialog,
    };
  }, [StudentsUIContext]);

  // Redux
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.students.actionsLoading,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!studentsUIProps.id) {
      studentsUIProps.closeDeleteDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsUIProps.id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteStudent = () => {
    // send request to server for remove student
    dispatch(
      actions.removeStudent(studentsUIProps.userId, studentsUIProps.id)
    ).then(() => {
      // refresh list after deletion
      dispatch(
        actions.fetchStudents(
          studentsUIProps.queryParams,
          studentsUIProps.userId
        )
      );
    });
    // closing delete modal
    studentsUIProps.closeDeleteDialog();
  };

  return (
    <Modal
      show={studentsUIProps.showDeleteDialog}
      onHide={studentsUIProps.closeDeleteDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {intl.formatMessage({
            id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.REMOVE.TITLE',
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>
            {intl.formatMessage({
              id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.REMOVE.MESSAGE',
            })}
          </span>
        )}
        {isLoading && (
          <span>
            {intl.formatMessage({
              id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.REMOVE.REMOVING',
            })}
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={studentsUIProps.closeDeleteDialog}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({
              id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.REMOVE.BUTTON.CANCEL',
            })}
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteStudent}
            className="btn btn-danger btn-elevate"
          >
            {intl.formatMessage({
              id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.REMOVE.BUTTON.REMOVE',
            })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
