import React from 'react';

export function UserReadProfile({ user }) {


    return (
        <>
            <div className="card gutter-b mb-0" style={{ boxShadow: 0, border: 0 }}>
                <div className="card-body pb-2">
                    <div className="row d-flex">
                        {/* begin:: Pic */}
                        <div className="flex-shrink-0 mr-7">
                            <div className="symbol symbol-120 symbol-light-danger">
                                <span className="font-size-h3 symbol-label font-weight-boldest">
                                    {user.firstName[0]}
                                </span>
                            </div>
                        </div>
                        {/* end:: Pic */}
                        {/* begin:: Info */}
                        <div className="flex-grow-1">
                            {/* begin:: Title */}
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                {/* begin:: User */}
                                <div className="mr-3 mb-3">
                                    <div className="d-flex align-items-center mr-3">
                                        {/* begin:: Name */}
                                        <p className="d-flex align-items-center font-weight-bolder font-size-h5 text-dark-75 text-hover-primary mb-0 mr-3">
                                            {user.title} {user.firstName} {user.lastName}
                                        </p>
                                        {/* end:: Name */}
                                    </div>
                                </div>
                                {/* end:: User */}
                            </div>
                            {/* end:: Title */}
                            {/* begin:: Student No */}
                            <div className="d-flex align-items-center flex-wrap">
                                <span className="text-muted"></span>
                                <i className="fas fa-user text-primary mr-3"></i>
                                <p className="text-dark mb-0">{user.personalId}</p>
                            </div>
                            {/* begin:: Student No */}
                            <div className="d-flex align-items-center flex-wrap">
                                <span className="text-muted"></span>
                                <i className="fas fa-school text-primary mr-3"></i>
                                <p className="text-dark mb-0">
                                    {user.degreeProgram.name} {user.degreeProgram.major}
                                </p>
                            </div>
                            {/* end:: SStudent degreeProgram */}
                            {/* begin:: Student Email */}
                            <div className="d-flex align-items-center flex-wrap">
                                <i className="fas fa-envelope text-primary mr-3"></i>
                                <p className="text-dark mb-0">{user.email}</p>
                            </div>
                            {/* end:: Student Email */}
                            {/* begin:: Student Gender */}
                            <div className="d-flex align-items-center flex-wrap">
                                <i className="fas fa-venus-mars text-primary mr-3"></i>
                                <p className="text-dark mb-0">{user.gender}</p>
                            </div>
                            {/* end:: Student Gender */}
                        </div>
                        {/* end:: Info */}
                    </div>
                </div>
            </div>

        </>
    )
}