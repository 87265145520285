/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { useHtmlClassService } from "../../../layout";
import { KTUtil } from "../../../_assets/js/components/util";
import axios from "axios";
import { useIntl } from 'react-intl';

export function DiaryMixedWidget({ className }) {
  const intl = useIntl();
  const [totalDiary, setTotalDiary] = useState(null);
  const [readDiary, setReadDiary] = useState(null);
  const [unreadDiary, setUnreadDiary] = useState(null);
  const [value, setValue] = useState('weekly');
  const percent = percentage(totalDiary, readDiary);
  const uiService = useHtmlClassService();


  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart");
    if (!element) {
      return;
    }
    const height = parseInt(KTUtil.css(element, 'height'));
    const options = getChartOptions(layoutProps, height, percent, value);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };

  }, [layoutProps, percent, value]);

  // Fetch diary (week,month,year)
  useEffect(() => {
    axios.get(`stat/diary?frequency=${value}`)
      .then(response => {
        const totalDiary = response.data.data.totalCount;
        const readDiary = response.data.data.read;
        const unreadDiary = response.data.data.unread;
        setTotalDiary(totalDiary);
        setReadDiary(readDiary);
        setUnreadDiary(unreadDiary);
      })
      .catch(error => {
        console.log('Fetching diary has error', error);
      })
  }, [value])

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title font-weight-bolder ">{intl.formatMessage({ id: "DASHBOARD.WIDGET.DIARY_CHART.TITLE" })}</h3>
        <div className="card-toolbar">
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              id="dropdown-toggle-top2"
              variant="transparent"
              className="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle">
              {intl.formatMessage({ id: "DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.VIEW" })}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              {/*begin::Navigation*/}
              <ul className="navi navi-hover">
                <li className="navi-header font-weight-bold py-5">
                  <span className="font-size-lg">{intl.formatMessage({ id: "DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.TITLE" })}</span>
                  <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right"
                    title={intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.INFO' })}></i>
                </li>
                <li className="navi-separator mb-3 opacity-70"></li>
                <li className="navi-item">
                  <a className="navi-link" onClick={() => setValue('weekly')} >
                    <span className="navi-text">
                      <span className="label label-xl label-inline label-light-success">{intl.formatMessage({ id: "DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.WEEKLY" })}</span>
                    </span>
                  </a>
                </li>
                <li className="navi-item">
                  <a className="navi-link" onClick={() => setValue('monthly')}>
                    <span className="navi-text">
                      <span className="label label-xl label-inline label-light-danger">{intl.formatMessage({ id: "DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.MONTHLY" })}</span>
                    </span>
                  </a>
                </li>
                <li className="navi-item">
                  <a className="navi-link" onClick={() => setValue('yearly')}>
                    <span className="navi-text">
                      <span className="label label-xl label-inline label-light-warning">{intl.formatMessage({ id: "DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.YEARLY" })}</span>
                    </span>
                  </a>
                </li>
              </ul>
              {/*end::Navigation*/}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div id="kt_mixed_widget_14_chart" style={{ height: "200px" }}></div>
        </div>
        <div className="pt-5">
          <p className="text-center font-weight-normal font-size-lg">
            {`${intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_CHART.SUB' })} `}
            {value === 'weekly' ? intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.WEEKLY' })
              : value === 'monthly' ? intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.MONTHLY' })
                : intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_CHART.DROPDOWN.YEARLY' })}
          </p>

          {/* table readed unred all */}
          <table className="table table-borderless table-vertical-center">
            <tbody>
              <tr>
                <td className="pl-0">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                  >
                    {intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_CHART.READED' })}
                  </a>
                </td>
                <td className="text-right">
                  <span className="label label-lg label-light-success label-inline">
                    {readDiary}
                  </span>
                </td>
              </tr>

              <tr>
                <td className="pl-0">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                  >
                    {intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_CHART.UNREAD' })}
                  </a>
                </td>
                <td className="text-right">
                  <span className="label label-lg label-light-danger label-inline">
                    {unreadDiary}
                  </span>
                </td>
              </tr>

              <tr>
                <td className="pl-0">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                  >
                    {intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_CHART.ALL' })}
                  </a>
                </td>
                <td className="text-right">
                  <span className="label label-lg label-light-primary label-inline">
                    {totalDiary}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function percentage(num, per) {
  return per / (num / 100);
};

function getChartOptions(layoutProps, height, percent, value) {
  const options = {
    series: [percent ? percent.toFixed() : 0],
    chart: {
      height: height,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "65%"
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
            fontWeight: "700",
          },
          value: {
            color: layoutProps.colorsGrayGray700,
            fontSize: "30px",
            fontWeight: "700",
            offsetY: 12,
            show: true
          },
        },
        track: {
          background: value === 'weekly' ? 'rgba(201, 247, 245, 0.85)' : value === 'monthly' ? '#FFE2E5' : '#FFF4DE',
          strokeWidth: '100%'
        }
      }
    },
    colors: [value === 'weekly' ? 'rgba(27, 197, 189, 0.85)' : value === 'monthly' ? '#F64E60' : '#FFA800'],  // green base :  rgba(27, 197, 189, 0.85) / light : rgba(201, 247, 245, 0.85);
    stroke: {                                                                                                 // red base : #F64E60 / light : #FFE2E5 // yellow base : #FFA800 / light : #FFF4DE;
      lineCap: "round",
    },
    labels: ["Progress"]
  };
  return options;
}
