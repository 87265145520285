import React from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { UserReadProfile } from './UserReadProfile';


export function UserRead({ show, user, onHide }) {
    const intl = useIntl();
    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={onHide}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: 'DASHBOARD.WIDGET.USERS_READ.TITLE' })}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="overlay overlay-block cursor-default">
                    {user ? <UserReadProfile user={user} /> : null}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light-danger btn-elevate"
                    >
                        {intl.formatMessage({ id: 'DASHBOARD.WIDGET.USERS_READ.BUTTON_CLOSE' })}
                    </button>
                </Modal.Footer>


            </Modal>

        </>
    )
}