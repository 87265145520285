import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from "../../../_helpers";
import axios from 'axios';
import { useIntl } from 'react-intl';

export function HeaderWidget3({ className }) {
    const intl = useIntl();
    const [topWord, setTopWord] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        axios.get(`stat/hashtag?frequency=weekly&limit=1`) // set frequency & limit
            .then(response => {
                const topWord = response.data.data;
                setTopWord(topWord);
                setLoading(false);
            })
            .catch(error => {
                console.log('Fetching hashtag has error', error);
                setLoading(false);
            })

    }, [])

    return (
        <>
            <div className={`card card-custom bg-warning ${className}`}>
                <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-white">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Flag.svg")} />
                    </span>
                    <span className="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block">
                        {!loading
                            ? (topWord && topWord.length
                                ? (topWord.map((data) => <span key={data._id}>{`${data._id}`}</span>))
                                : intl.formatMessage({ id: "DASHBOARD.WIDGET.HASHTAG.NO_DATA" }))
                            : intl.formatMessage({ id: "DASHBOARD.WIDGET.HASHTAG.LOADING" })}</span>
                    <span className="font-weight-bold text-white font-size-sm">{intl.formatMessage({ id: "DASHBOARD.HEADER.WIDGET_3" })}</span>
                </div>

            </div>
        </>
    )
}