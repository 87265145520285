import React from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { ModalProgressBar } from '../../../../../../_metronic/_partials/controls';

export default function StudentAppointDialogHeader() {
  const intl = useIntl();
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.students.actionsLoading,
    }),
    shallowEqual
  );
  return (
    <>
      {actionsLoading && <ModalProgressBar variant="" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {intl.formatMessage({
            id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.TITLE',
          })}
        </Modal.Title>
      </Modal.Header>
    </>
  );
}
