/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSubheader } from "../../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import * as actions from "../../_redux/users/usersActions";
import Students from "../user-students/Students";
import { StudentsUIProvider } from "../user-students/StudentsUIContext";
import { UserTeacher } from "../user-teacher/UserTeacher";
import { UserEditForm } from "./UserEditForm";

const initUser = {
  _id: undefined,
  title: "",
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  personalId: "",
  degreeProgram: {
    _id: undefined,
  },
  role: "",
  status: "",
  avatar: "",
};

export function UserEdit({
  history,
  match: {
    params: { id },
  },
}) {
  const intl = useIntl();
  //  Subheader
  const subheader = useSubheader();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");

  // Redux
  const dispatch = useDispatch();
  const { actionsLoading, userForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      userForEdit: state.users.userForEdit,
    }),
    shallowEqual
  );

  // Fetch user by id
  useEffect(() => {
    dispatch(actions.fetchUser(id));
  }, [id, dispatch]);

  // Set title
  useEffect(() => {
    let _title = id ? "" : "New user";
    if (userForEdit && id) {
      _title = `${intl.formatMessage({
        id: "USER_MANAGEMENT.PAGES.EDIT.TITLE",
      })} '${userForEdit.personalId} - ${userForEdit.firstName} ${
        userForEdit.lastName
      }'`;
    }

    setTitle(_title);
    subheader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForEdit, id]);

  // Save Update
  const saveUser = (value) => {
    if (!id) {
      backToUsersList();
      //   dispatch(actions.createUser(value)).then(() => backToUsersList());
    } else {
      dispatch(actions.updateUser(id, value)).then(() => backToUsersList());
    }
  };

  const btnRef = useRef();
  const saveUserClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  // Back
  const backToUsersList = () => {
    history.push(`/user-management`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      {/* begin:: card header */}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToUsersList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>

            {intl.formatMessage({
              id: "USER_MANAGEMENT.PAGES.EDIT.BACK",
            })}
          </button>

          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveUserClick}
          >
            {intl.formatMessage({
              id: "USER_MANAGEMENT.PAGES.EDIT.SAVE",
            })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      {/* end:: card header */}
      {/* begin:: card body */}
      <CardBody>
        {/* begin:: tabs */}
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}
            >
              {intl.formatMessage({
                id: "USER_MANAGEMENT.PAGES.EDIT.TABS.BASIC_INFO",
              })}
            </a>
          </li>
          {id && (
            <>
              {userForEdit && userForEdit.role === "student" ? (
                <li className="nav-item" onClick={() => setTab("teacher")}>
                  <a
                    className={`nav-link ${tab === "teacher" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "teacher").toString()}
                  >
                    {intl.formatMessage({
                      id: "USER_MANAGEMENT.PAGES.EDIT.TABS.TEACHER",
                    })}
                  </a>
                </li>
              ) : (
                <li className="nav-item" onClick={() => setTab("students")}>
                  <a
                    className={`nav-link ${tab === "students" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "students").toString()}
                  >
                    {intl.formatMessage({
                      id: "USER_MANAGEMENT.PAGES.EDIT.TABS.STUDENTS",
                    })}
                  </a>
                </li>
              )}
            </>
          )}
        </ul>
        {/* end:: tabs */}
        <div className="mt-5">
          {tab === "basic" && (
            <UserEditForm
              actionsLoading={actionsLoading}
              user={userForEdit || initUser}
              btnRef={btnRef}
              saveUser={saveUser}
            />
          )}
          {tab === "students" && id && (
            <StudentsUIProvider currentUserId={id}>
              <Students />
            </StudentsUIProvider>
          )}
          {tab === "teacher" && id && (
            <UserTeacher id={userForEdit.teacher}></UserTeacher>
          )}
        </div>
      </CardBody>
      {/* end:: card body */}
    </Card>
  );
}
