import React from 'react';
import { Card, CardBody } from '../../../../_metronic/_partials/controls';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashtagsTable } from './hashtags-table/HashtagsTable';
import { HashtagsChart } from './HashtagsChart';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";


export function HashtagsCard() {

    return (
        <Card>
            <CardBody>
                <Router>
                    <Route exact path="/hashtags" component={HashtagsChart} />
                    <Route path="/hashtags/table" component={HashtagsTable} />
                </Router>
            </CardBody>
        </Card>

    )

}