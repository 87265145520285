/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive, toAbsoluteUrl } from '../../../../_helpers';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin: Home page :1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
          aria-haspopup='true'
        >
          <NavLink className='menu-link' to='/dashboard'>
            <i className='icon-lg fas fa-home menu-icon'></i>
            <span className='menu-text'>
              {intl.formatMessage({ id: 'ASIDE.MENU.HOMEPAGE' })}
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin: Hashtags :1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/hashtags', false)}`}
          aria-haspopup='true'
        >
          <NavLink className='menu-link' to='/hashtags'>
            <i className='icon-lg fab fa-slack-hash menu-icon'></i>
            <span className='menu-text'>
              {intl.formatMessage({ id: 'ASIDE.MENU.HASHTAG' })}
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        <li className='menu-section '>
          <h4 className='menu-text'>Management</h4>
          <i className='menu-icon flaticon-more-v2'></i>
        </li>
        {/* end:: section */}
        {/*begin: Users :1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            '/user-management',
            false
          )}`}
          aria-haspopup='true'
        >
          <NavLink className='menu-link' to='/user-management'>
            <i className='icon-lg fas fa-users-cog menu-icon'></i>
            <span className='menu-text'>
              {intl.formatMessage({ id: 'ASIDE.MENU.USER_MANAGEMENT' })}
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Diary Management*/}
        {/* begin::1 Level */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/google-material',
            true
          )}`}
          aria-haspopup='true'
          data-menu-toggle='hover'
        >
          <NavLink className='menu-link menu-toggle' to='/google-material'>
            <span className='svg-icon menu-icon'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Cap-2.svg')} />
            </span>
            <span className='menu-text'>
              {intl.formatMessage({ id: 'ASIDE.MENU.DIARY' })}
            </span>
            <i className='menu-arrow' />
          </NavLink>
          <div className='menu-submenu '>
            <i className='menu-arrow' />
            <ul className='menu-subnav'>
              <li className='menu-item  menu-item-parent' aria-haspopup='true'>
                <span className='menu-link'>
                  <span className='menu-text'>
                    {intl.formatMessage({ id: 'ASIDE.MENU.DIARY' })}
                  </span>
                </span>
              </li>

              {/* Overview */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  '/diary-management/overview',
                  true
                )}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
              >
                <NavLink
                  className='menu-link menu-toggle'
                  to='/diary-management/overview'
                >
                  <i className='menu-bullet menu-bullet-dot'>
                    <span />
                  </i>
                  <span className='menu-text'>
                    {intl.formatMessage({ id: 'ASIDE.MENU.DIARY.OVERVIEW' })}
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Overview */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  '/diary-management/list',
                  true
                )}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
              >
                <NavLink
                  className='menu-link menu-toggle'
                  to='/diary-management/list'
                >
                  <i className='menu-bullet menu-bullet-dot'>
                    <span />
                  </i>
                  <span className='menu-text'>
                    {intl.formatMessage({ id: 'ASIDE.MENU.DIARY.LIST' })}
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Overview */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  '/diary-management/report/weekly',
                  true
                )}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
              >
                <NavLink
                  className='menu-link menu-toggle'
                  to='/diary-management/report/weekly'
                >
                  <i className='menu-bullet menu-bullet-dot'>
                    <span />
                  </i>
                  <span className='menu-text'>
                    {intl.formatMessage({
                      id: 'ASIDE.MENU.DIARY.REPORT.WEEKLY',
                    })}
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/* end::1 Level */}

        {/* News Management */}
        <li
          className={`menu-item ${getMenuItemActive(
            '/news-management',
            false
          )}`}
          aria-haspopup='true'
        >
          <NavLink className='menu-link' to='/news-management'>
            <i className='menu-icon icon-lg far fa-newspaper'></i>
            <span className='menu-text'>
              {intl.formatMessage({ id: 'ASIDE.MENU.NEWS' })}
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
