import axios from "axios";

export const AUTH_URL = "/auth";
export const USER_URL = "/users";
export const STUDENTS_SEARCH_URL = "/student/search";
export const TEACHER_APPOINT_URL = "/teacher/appoint";

// READ => GET: FIND USER API
export function findUsers(queryParams) {
  return axios.get(USER_URL, { params: queryParams });
}

// READ => GET FIND ONE USER
export function findUser(id) {
  return axios.get(`${USER_URL}/${id}`);
}

// READ => GET: FIND STUDENT
export function searchStudent(type, query) {
  return axios.get(STUDENTS_SEARCH_URL, {
    params: {
      type,
      query,
      limit: 12,
    },
  });
}

// UPDATE => PUT: EDIT USER PROFILE
export function updateUser(id, user) {
  return axios.put(`${USER_URL}/${id}`, user);
}

// UPDATE => PUT: TEACHER APPOINTMENT
// userId = teacher user id
export function updateAppoint(userId, ids) {
  return axios.put(`${TEACHER_APPOINT_URL}/${userId}`, {
    userIds: ids,
  });
}

// GET => USER NEW PASSWORD URL
export function forgetPassword(userId) {
  return axios.get(AUTH_URL + "/forget_password/" + userId);
}
