import axios from 'axios';

export const STUDENTS_SEARCH_URL = 'student/search';
export const TEACHER_URL = 'teacher';

// READ
export function findStudents(queryParams) {
  return axios.get('/users', { params: queryParams });
}

// READ => Search Student
export function searchStudents(type, query, limit) {
  return axios.get(STUDENTS_SEARCH_URL, {
    params: {
      type,
      query,
      limit,
    },
  });
}

// PUT: Update appointed student
// tUserId=teacher user id, sUserId=student user id
export function appoint(tUserId, sUserIds) {
  return axios.put(`${TEACHER_URL}/appoint/${tUserId}`, sUserIds);
}

// DELETE => Remove user
export function remove(tUserId, sUserId) {
  console.log('sUserId', sUserId);
  return axios.delete(`${TEACHER_URL}/remove/${tUserId}`, { data: sUserId });
}
