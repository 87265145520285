import * as requestFromServer from './studentsCrud';
import { studentsSlice, callTypes } from './studentsSlice';
import { toast } from 'react-toastify';

const { actions } = studentsSlice;

export const fetchStudents = (queryParams, userId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  if (!userId) {
    return dispatch(actions.studentsFetched({ totalCount: 0, entities: null }));
  }

  queryParams.filter = {
    teacher: userId,
  };

  return requestFromServer
    .findStudents(queryParams)
    .then((response) => {
      const totalCount = response.data.totalCount;
      const entities = response.data.data;
      dispatch(actions.studentsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find students";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const appointStudent = (tUserId, student) => (dispatch) => {
  // convert student id to array
  const userIds = [student._id];

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .appoint(tUserId, { userIds })
    .then(() => {
      toast.success('สำเร็จ 🥳');
      dispatch(actions.studentsAppointed({ student }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update students";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const removeStudent = (tUserId, sUserId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .remove(tUserId, { userId: sUserId })
    .then((response) => {
      toast.success(response.data.message);
      dispatch(actions.studentRemoved());
    })
    .catch((error) => {
      error.clientMessage = "Can't delete student";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
