import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./HashtagsUIHelpers";

const HashtagsUIContext = createContext();

export function useHashtagsUIContext() {
    return useContext(HashtagsUIContext);
}

export const HashtagsUIConsumer = HashtagsUIContext.Consumer;

export function HashtagsUIProvider({ hashtagsUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initHashtags = {
        text: "",
        totalCount: ""
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        initHashtags
    };
    return (
        <HashtagsUIContext.Provider value={value}>{children}</HashtagsUIContext.Provider>
    );
}
