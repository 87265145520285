/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from "../../../_helpers";
import { useIntl } from 'react-intl';

export function HeaderWidget1({ className }) {
    const intl = useIntl();
    const [newUser, setNewUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        axios.get(`stat/user?frequency=weekly`) // weekly, monthly, yearly
            .then(response => {
                const newUser = response.data.data.totalCount;
                setNewUser(newUser);
                setLoading(false);
            })
            .catch(error => {
                console.log('Fetch new user error', error);
                setLoading(false);
            })
    }, [])
    return (
        <>
            <div className={`card card-custom ${className}`}>
                <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-info">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
                    </span>
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">+ {!loading ? newUser : "X"}</span>
                    <span className="font-weight-bold text-muted font-size-sm">{intl.formatMessage({ id: "DASHBOARD.HEADER.WIDGET_1" })}</span>
                </div>

            </div>
        </>
    )
}