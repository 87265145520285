import { isEqual, isFunction } from "lodash";
import React, { createContext, useCallback, useContext, useState } from "react";
import { initialFilter } from "./UsersUIHelpers";

const UsersUIContext = createContext();

export function useUsersUIContext() {
  return useContext(UsersUIContext);
}

export const UsersUIConsumer = UsersUIContext.Consumer;

export function UsersUIProvider({ usersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initUser = {
    _id: undefined,
    email: "",
    title: "",
    firstName: "",
    lastName: "",
    gender: "",
    personalId: "",
    role: "",
    status: "",
    avatar: "",
    degreeProgram: undefined,
    birthDate: "",
    phoneNumber: "",
    students: undefined,
    teacher: undefined,
    createdAt: "",
    updatedAt: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initUser,
    openEditUserPage: usersUIEvents.openEditUserPage,
    openNewPasswordDialog: usersUIEvents.openNewPasswordDialog,
  };

  return (
    <UsersUIContext.Provider value={value}>{children}</UsersUIContext.Provider>
  );
}
