import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { useClipboard } from "use-clipboard-copy";

export function UserNewPasswordForm({ requestPassword, url, user }) {
  // Hooks
  const clipboard = useClipboard({
    copiedTimeout: 1000, // timeout duration in milliseconds
  });
  return (
    <>
      <p>
        คุณต้องการสร้างลิ้งเปลี่ยนรหัสผ่านของ '{user.firstName} {user.lastName}{" "}
        ({user.personalId})' จริงหรือไม่?
      </p>
      <div>
        <InputGroup>
          <FormControl
            placeholder="ลิ้งจะมีเวลาหมดอายุ 1 วัน หลังจากสร้าง"
            aria-label="Reset password url"
            aria-describedby="basic-addon2"
            defaultValue={url}
            ref={clipboard.target}
            readOnly
          />
          <InputGroup.Append>
            <Button variant="primary" onClick={() => requestPassword(user._id)}>
              สร้าง
            </Button>
            <Button variant="success" onClick={clipboard.copy}>
              {clipboard.copied ? (
                "Copied"
              ) : (
                <i className="fas fa-copy icon-md"></i>
              )}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
    </>
  );
}
