import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/hashtagsActions";
import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../HashtagsUIHelpers";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useHashtagsUIContext } from "../HashtagsUIContext";
import { useIntl } from 'react-intl';
import { HashtagsFilter } from '../hashtags-filter/HashtagsFilter';

export function HashtagsTable() {
    const intl = useIntl();
    const hashtagsUIContext = useHashtagsUIContext();
    const hashtagsUIProps = useMemo(() => {
        return {
            ids: hashtagsUIContext.ids,
            setIds: hashtagsUIContext.setIds,
            queryParams: hashtagsUIContext.queryParams,
            setQueryParams: hashtagsUIContext.setQueryParams,
        };
    }, [hashtagsUIContext]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.hashtags }),
        shallowEqual
    );
    const { totalCount, hashtags, listLoading } = currentState;

    const dispatch = useDispatch();
    useEffect(() => {
        // clear selections list
        hashtagsUIProps.setIds([]);
        // server call by queryParams
        dispatch(actions.fetchHashtags(hashtagsUIProps.queryParams));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hashtagsUIProps.queryParams, dispatch]);
    // Table columns

    const columns = [
        {
            dataField: "_id",
            text: intl.formatMessage({ id: 'HASHTAGS.FILTER.TABLE.HEADER.HASHTAGS' }),
            sort: false,

        },
        {
            dataField: "totalCount",
            text: intl.formatMessage({ id: 'HASHTAGS.FILTER.TABLE.HEADER.COUNT' }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,

        },
    ];

    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: hashtagsUIProps.queryParams.pageSize,
        page: hashtagsUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <HashtagsFilter />
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                remote
                                keyField="_id"
                                data={hashtags === null ? [] : hashtags}
                                columns={columns}
                                defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    hashtagsUIProps.setQueryParams
                                )}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage hashtags={hashtags} />
                                <NoRecordsFoundMessage hashtags={hashtags} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
}
