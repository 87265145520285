import React from "react";
import { Route } from "react-router-dom";
import { UsersLoadingDialog } from "./users-loading-dialog/UserLoadingDialog";
import UsersCard from "./UsersCard";
import { UsersUIProvider } from "./UsersUIContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import UserNewPasswordDialog from "./user-new-password-dialog/UserNewPasswordDialog";

export default function UsersPage({ history }) {
  const usersUIEvents = {
    openEditUserPage: (id) => {
      history.push(`/user-management/${id}/edit`);
    },
    openNewPasswordDialog: (id) => {
      history.push(`/user-management/${id}/forget`);
    },
  };
  return (
    <UsersUIProvider usersUIEvents={usersUIEvents}>
      <UsersLoadingDialog />
      <Route path="/user-management/:id/forget">
        {({ history, match }) => (
          <UserNewPasswordDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/user-management/");
            }}
          />
        )}
      </Route>

      <UsersCard />
      <ToastContainer />
    </UsersUIProvider>
  );
}
