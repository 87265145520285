import * as requestFromServer from "./hashtagsCrud";
import { hashtagsSlice, callTypes } from "./hashtagsSlice";

const { actions } = hashtagsSlice;

export const fetchHashtags = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findHashtags(queryParams)
        .then((response) => {
            const totalCount = response.data.totalCount;
            const hashtags = response.data.data;
            dispatch(actions.hashtagsFetched({ totalCount, hashtags }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find hashtags";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

