import dayjs from "dayjs";

export const defaultSorted = [{ dataField: "totalCount", order: "desc" }];
export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
];
export const initialFilter = {
    filter: {
        startDate: dayjs().startOf('month').toISOString(),
        endDate: dayjs().toISOString()
    },
    sortOrder: "desc", // asc||desc
    sortField: "_id",
    pageNumber: 1,
    pageSize: 10,
};
