import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Badge } from 'react-bootstrap';
import { URL } from '../../../../config/configuration';
import DiaryReadDialogSentiment from '../../../../app/modules/Diary/pages/diary/diary-read-dialog/DiaryReadDialogSentiment';

export function DiaryRead({ show, diaryId, onHide }) {
  const intl = useIntl();
  const [diary, setDiary] = useState('');

  useEffect(() => {
    if (diaryId) {
      axios
        .get(`diary/${diaryId}`)
        .then((response) => {
          const diary = response.data.data;
          setDiary(diary);
        })
        .catch((error) => {
          console.log('Fetching diary read has error', error);
        });
    }
  }, [diaryId]);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {intl.formatMessage({ id: 'DASHBOARD.WIDGET.DIARY_READ.TITLE' })}
            {diary.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {diary ? (
            <>
              <div className="readdialog__owner d-flex align-items-center mb-5 border border-secondary py-5 px-4 rounded">
                {/* begin::Symbol */}
                <div className="symbol symbol-50 symbol-light-success mr-5">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: `url(${URL.imagePrefix}/${diary.owner.avatar})`,
                    }}
                  ></div>
                </div>
                {/* end::Symbol */}
                {/* begin::Text */}
                <div className="d-flex flex-column flex-grow-1">
                  <h5>{`${diary.owner.title}${diary.owner.firstName} ${diary.owner.lastName}`}</h5>
                  <span className="text-muted font-weight-bold">
                    {`${intl.formatMessage({
                      id: 'DIARY_MANAGEMENT.READ.BODY.CREATEDAT',
                    })} ${dayjs(diary.createdAt).format('DD/MM/YYYY')}`}
                    {diary.createdAt !== diary.updatedAt &&
                      ` • 
                      ${intl.formatMessage({
                        id: 'DIARY_MANAGEMENT.READ.BODY.UPDATEDAT',
                      })}
                      ${dayjs(diary.createdAt)
                        .locale('th')
                        .fromNow()}`}
                  </span>
                </div>
              </div>

              {/* Story */}
              <div className="readdialog__desc">
                <h3 className="font-weight-bold">
                  {intl.formatMessage({
                    id: 'DASHBOARD.WIDGET.DIARY_READ.DESCRIPTION',
                  })}
                </h3>
                {diary && (
                  <div className="readdialog__desc__sentiment">
                    <DiaryReadDialogSentiment
                      sentiment={diary.storySentiment || undefined}
                    />
                  </div>
                )}
                <p className="lead">{diary.story}</p>
              </div>

              {/* Summerize */}
              <div className="readdialog__sum">
                <h3 className="font-weight-bold">
                  {intl.formatMessage({
                    id: 'DASHBOARD.WIDGET.DIARY_READ.SUMMARIZE',
                  })}
                </h3>
                {diary && (
                  <div className="readdialog__desc__sentiment">
                    <DiaryReadDialogSentiment
                      sentiment={diary.summarizeSentiment || undefined}
                    />
                  </div>
                )}
                <p className="lead">{diary.summarize}</p>
              </div>

              {/* hashtags */}
              <div className="readdialog__hashtags flex d-flex">
                <h3 className="mr-2">
                  {intl.formatMessage({
                    id: 'DIARY_MANAGEMENT.READ.BODY.HASHTAGS',
                  })}
                </h3>
                <div>
                  {diary ? (
                    diary.hashtags && diary.hashtags.length ? (
                      diary.hashtags.map((data, index) => (
                        <Badge
                          pill
                          variant="secondary"
                          className="mr-2 mb-2"
                          key={index}
                        >
                          {data}
                        </Badge>
                      ))
                    ) : (
                      <p>
                        {intl.formatMessage({
                          id: 'DIARY_MANAGEMENT.READ.BODY.NO_HASHTAGS',
                        })}
                      </p>
                    )
                  ) : (
                    <p>
                      {intl.formatMessage({
                        id: 'DIARY_MANAGEMENT.READ.BODY.HASHTAGS_LOADING',
                      })}
                    </p>
                  )}
                </div>
              </div>

              {/* reaction */}
              <div className="readdialog__reaction  flex d-flex">
                <h3 className="text-dark font-weight-bold mr-2">
                  {intl.formatMessage({
                    id: 'DIARY_MANAGEMENT.READ.BODY.REACTION',
                  })}
                </h3>

                {diary ? (
                  diary.status.isRead ? (
                    <div className="reaction">
                      <span className="badge badge-pill badge-primary">
                        {diary.status.reaction.icon +
                          ' ' +
                          diary.status.reaction.name}{' '}
                      </span>
                      {intl.formatMessage({
                        id: 'DIARY_MANAGEMENT.READ.BODY.BY',
                      })}{' '}
                      {diary.status.reader.title}{' '}
                      {diary.status.reader.firstName}{' '}
                      {diary.status.reader.lastName}
                    </div>
                  ) : (
                    <span className="label label-warning label-inline">
                      {intl.formatMessage({
                        id: 'DIARY_MANAGEMENT.READ.BODY.NO_REACTION',
                      })}
                    </span>
                  )
                ) : (
                  intl.formatMessage({
                    id: 'DIARY_MANAGEMENT.READ.BODY.HASHTAGS_LOADING',
                  })
                )}
              </div>
            </>
          ) : (
            <Spinner animation="border" role="status">
              c
              <span className="sr-only">
                {intl.formatMessage({
                  id: 'DASHBOARD.WIDGET.DIARY_READ.LOADING',
                })}
              </span>
            </Spinner>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light-danger btn-elevate"
          >
            {intl.formatMessage({
              id: 'DASHBOARD.WIDGET.DIARY_READ.BUTTON_CLOSE',
            })}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
