import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useStudentsUIContext } from '../StudentsUIContext';
import StudentAppointDialogHeader from './StudentAppointDialogHeader';
import { StudentAppointForm } from './StudentAppointForm';
import * as actions from '../../../_redux/students/studentsActions';

export function StudentAppointDialog(props) {
  const studentsUIContext = useStudentsUIContext();
  const studentsUIProps = useMemo(() => {
    return {
      userId: studentsUIContext.userId,
      showAppointDialog: studentsUIContext.showAppointDialog,
      closeAppointDialog: studentsUIContext.closeAppointDialog,
      initUser: studentsUIContext.initUser,
    };
  }, [studentsUIContext]);

  // Store
  const dispatch = useDispatch();
  const { actionsLoading } = useSelector((state) => ({
    actionsLoading: state.students.actionsLoading,
  }));

  const saveStudent = (student) => {
    if (student) {
      dispatch(
        actions.appointStudent(studentsUIProps.userId, student)
      ).then(() => studentsUIProps.closeAppointDialog());
    }
  };

  return (
    <Modal
      size="lg"
      show={studentsUIProps.showAppointDialog}
      onHide={studentsUIProps.closeAppointDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <StudentAppointDialogHeader />
      <StudentAppointForm
        saveStudent={saveStudent}
        actionsLoading={actionsLoading}
        onHide={studentsUIProps.closeAppointDialog}
      />
    </Modal>
  );
}
