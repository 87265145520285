import React, { useMemo } from 'react';
import { useStudentsUIContext } from './StudentsUIContext';
import { useIntl } from 'react-intl';

export function StudentsFilter() {
  const intl = useIntl();
  // Students UI Context
  const studentsUIContext = useStudentsUIContext();
  const studentsUIProps = useMemo(() => {
    return {
      setQueryParams: studentsUIContext.setQueryParams,
      openAppointDialog: studentsUIContext.openAppointDialog,
      queryParams: studentsUIContext.queryParams,
    };
  }, [studentsUIContext]);

  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-10 margin-bottom-10-mobile"></div>
          <div className="col-md-2 text-right margin-bottom-10-mobile">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => studentsUIProps.openAppointDialog()}
            >
              {intl.formatMessage({
                id: 'USER_MANAGEMENT.PAGES.EDIT.ADD_STUDENT',
              })}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
