import axios from "axios";

export const HASHTAGS_URL = "/hashtag";


export function findHashtags(queryParams) {
    return axios.get(`${HASHTAGS_URL}`, {
        params: queryParams,
    });
}
