import React, { forwardRef, useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import { useHashtagsUIContext } from "../HashtagsUIContext";
import { useIntl } from 'react-intl';
import * as dayjs from 'dayjs'
import { Link } from 'react-router-dom';
import '../../../hashtags/hashtagsStyle.scss';

const prepareFilter = (queryParams, values) => {

    const { text, startDate, endDate } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};


    if (text) {
        filter.text = text;
    }
    if (startDate) {
        filter.startDate = dayjs(startDate).startOf('day').toISOString();
    }
    if (endDate) {
        filter.endDate = dayjs(endDate).endOf('day').toISOString();
    }
    newQueryParams.filter = filter;
    console.log('filter', filter)

    return newQueryParams;

};

export function HashtagsFilter({ listLoading }) {
    const intl = useIntl();
    const hashtagsUIContext = useHashtagsUIContext();
    const hashtagsUIProps = useMemo(() => {
        return {
            queryParams: hashtagsUIContext.queryParams,
            setQueryParams: hashtagsUIContext.setQueryParams,
        };
    }, [hashtagsUIContext]);
    const ref = React.createRef()

    const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
    const [endDate, setEndDate] = useState(new Date());


    const FormStartDate = forwardRef(({ value, onClick }, ref) => (
        <Form.Group className="mb-0" controlId="formStartDate">
            <Form.Control type="text" placeholder={value} onClick={onClick} ref={ref} readOnly />
            <Form.Label>
                <small className="form-text text-muted">
                    <b>{intl.formatMessage({
                        id: "HASHTAGS.FILTER.SEARCH"
                    })}</b> {intl.formatMessage({
                        id: "HASHTAGS.FILTER.SEARCH.START_DATE"
                    })}
                </small>
            </Form.Label>
        </Form.Group>
    ));

    const FormEndDate = forwardRef(({ value, onClick }, ref) => (
        <Form.Group className="mb-0" controlId="formEndDate">
            <Form.Control type="text" placeholder={value} onClick={onClick} ref={ref} readOnly />
            <Form.Label>
                <small className="form-text text-muted">
                    <b>{intl.formatMessage({
                        id: "HASHTAGS.FILTER.SEARCH"
                    })}</b> {intl.formatMessage({
                        id: "HASHTAGS.FILTER.SEARCH.END_DATE"
                    })}
                </small>
            </Form.Label>
        </Form.Group>
    ));

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(hashtagsUIProps.queryParams, values);
        if (!isEqual(newQueryParams, hashtagsUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;

            hashtagsUIProps.setQueryParams(newQueryParams);
        }
    };

    return (
        <Formik
            initialValues={{
                text: "",
                startDate: startDate,
                endDate: endDate,
            }}
            onSubmit={(values) => {
                applyFilter(values);
            }}
        >
            {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="form form-label-right">
                    <div className="form-group row">
                        <div className="col-lg-3">
                            <input
                                type="text"
                                className="form-control"
                                name="text"
                                placeholder={intl.formatMessage({
                                    id: "HASHTAGS.FILTER.SEARCH.PLACE_HOLDER"
                                })}
                                onBlur={handleBlur}
                                value={values.text}
                                onChange={(e) => {
                                    setFieldValue("text", e.target.value);
                                    handleSubmit();
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>{intl.formatMessage({
                                    id: "HASHTAGS.FILTER.SEARCH"
                                })}</b> {intl.formatMessage({
                                    id: "HASHTAGS.FILTER.SEARCH.HASHTAG"
                                })}
                            </small>
                        </div>
                        <div className="col-lg-3">
                            <DatePicker
                                wrapperClassName="datePicker"
                                name="startDate"
                                selected={startDate}
                                value={dayjs(values.startDate).format('DD/MM/YYYY')}
                                onChange={(e) => {
                                    // e = date
                                    if (e > endDate) {
                                        setEndDate(dayjs(e).toDate());
                                        setFieldValue("endDate", e)
                                    }
                                    setStartDate(dayjs(e).toDate())
                                    setFieldValue("startDate", e);
                                    handleSubmit();
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                customInput={<FormStartDate ref={ref} />}
                            />

                        </div>
                        <div className="col-lg-3">
                            <DatePicker
                                wrapperClassName="datePicker"
                                name="endDate"
                                selected={endDate}
                                value={values.startDate > values.endDate ? dayjs(values.startDate).format('DD/MM/YYYY') : dayjs(values.endDate).format('DD/MM/YYYY')}
                                onChange={(e) => {

                                    setEndDate(dayjs(e).toDate())
                                    setFieldValue("endDate", e);
                                    handleSubmit();
                                }}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                customInput={<FormEndDate ref={ref} />}
                            />

                        </div>
                        <div>
                            <Link to="/hashtags">
                                <button className="btn btn-info">
                                    {intl.formatMessage({ id: "HASHTAGS.FILTER.CHART.BUTTON" })}
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}
