/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Dropdown, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import axios from 'axios';
import ApexCharts from 'apexcharts';
import dayjs from 'dayjs';

export function HashtagsChart() {
  const intl = useIntl();
  const [hashtagsSort, setHashtagsSort] = useState([]);
  const onDay = dayjs().format('M') - 1;
  const [month, setMonth] = useState(dayjs().format('M') - 1); // Jan started with 0
  const [sum, setSum] = useState(null);
  const monthShow = dayjs()
    .month(month)
    .format('MMM');
  const langMonth = intl.formatMessage({
    id: `HASHTAGS.CHART.DROPDOWN.${monthShow.toUpperCase()}`,
  });
  const monthDropdown = [
    dayjs().month(onDay),
    dayjs().month(onDay - 1),
    dayjs().month(onDay - 2),
    dayjs().month(onDay - 3),
    dayjs().month(onDay - 4),
    dayjs().month(onDay - 5),
    dayjs().month(onDay - 6),
    dayjs().month(onDay - 7),
    dayjs().month(onDay - 8),
    dayjs().month(onDay - 9),
    dayjs().month(onDay - 10),
    dayjs().month(onDay - 11),
  ];

  useEffect(() => {
    axios
      .get('hashtag', {
        params: {
          filter: {
            startDate: dayjs()
              .month(month)
              .startOf('month')
              .toISOString(),
            endDate: dayjs()
              .month(month)
              .endOf('month')
              .toISOString(),
          },
          sortOrder: 'desc',
          sortField: '_id',
          pageNumber: 1,
          pageSize: 500,
        },
      })
      .then((response) => {
        const data = response.data.data;
        const dataSort = data.length
          ? data.sort((a, b) => (a.totalCount < b.totalCount ? 1 : -1))
          : 0;
        const sum = data.length
          ? data.reduce((a, b) => a + (b['totalCount'] || 0), 0)
          : 0;
        console.log('data = ', data);
        console.log('dataSort = ', dataSort);
        console.log('sum = ', sum);
        setSum(sum);
        setHashtagsSort(dataSort);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [month]);

  useEffect(() => {
    const element = document.getElementById('hashtags_chart');
    if (!element) {
      return;
    }
    const hashtagsShow = [
      hashtagsSort[0] || 0,
      hashtagsSort[1] || 0,
      hashtagsSort[2] || 0,
      hashtagsSort[3] || 0,
      hashtagsSort[4] || 0,
      hashtagsSort[5] || 0,
    ];
    const sumOther = percentageOther(hashtagsShow, sum);
    const options = getChartOptions(hashtagsShow, sumOther);
    const chart = new ApexCharts(element, options);

    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [hashtagsSort, sum]);

  return (
    <>
      <div className='card-header flex-custom border-0'>
        <h3 className='card-title font-weight-bolder text-dark'>
          {intl.formatMessage({ id: 'HASHTAGS.CHART.TITLE' })}
        </h3>
        <div className='card-toolbar'>
          <Dropdown className='dropdown-inline' drop='down' alignRight>
            <Dropdown.Toggle
              id='dropdown-toggle-top2'
              variant='transparent'
              className='btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle'
            >
              {langMonth}
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu dropdown-month dropdown-menu-sm dropdown-menu-right'>
              {/*begin::Navigation*/}
              <ul className='navi navi-hover'>
                <li className='navi-header font-weight-bold py-5'>
                  <span className='font-size-lg'>
                    {intl.formatMessage({
                      id: 'HASHTAGS.CHART.DROPDOWN.TITLE',
                    })}
                  </span>
                  <i
                    className='flaticon2-information icon-md text-muted'
                    data-toggle='tooltip'
                    data-placement='right'
                    title='เลือกช่วงเดือนที่คุณต้องการ'
                  ></i>
                </li>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[1].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[0]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[0].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[2].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[1]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[1].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[3].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[2]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[2].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[4].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[3]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[3].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[5].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[4]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[4].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[6].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[5]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[5].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[7].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[6]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[6].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[8].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[7]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[7].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[9].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[8]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[8].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[10].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[9]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[9].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[11].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[10]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[10].format('YYYY')})`}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setMonth(monthDropdown[0].format('M'))}
                >
                  {`${intl.formatMessage({
                    id: `HASHTAGS.CHART.DROPDOWN.${monthDropdown[11]
                      .format('MMM')
                      .toUpperCase()}`,
                  })} (${monthDropdown[11].format('YYYY')})`}
                </Dropdown.Item>
              </ul>
              {/*end::Navigation*/}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        {hashtagsSort && hashtagsSort.length ? (
          <div
            id='hashtags_chart'
            style={{
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
            }}
          ></div>
        ) : (
          <Alert variant='warning'>
            {intl.formatMessage({ id: 'HASHTAGS.CHART.ALERT' })}
          </Alert>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Link to='/hashtags/table'>
          <button className='btn btn-primary'>
            {intl.formatMessage({ id: 'HASHTAGS.CHART.BUTTON' })}
          </button>
        </Link>
      </div>
    </>
  );
}

function percentageOther(hashtagsShow, sum) {
  const sumShow = hashtagsShow.reduce((a, b) => a + (b['totalCount'] || 0), 0);
  const sumOther = sum - sumShow; // 4
  return sumOther;
}

function getChartOptions(hashtagsShow, sumOther) {
  const options = {
    series: [
      hashtagsShow[0].totalCount || 0,
      hashtagsShow[1].totalCount || 0,
      hashtagsShow[2].totalCount || 0,
      hashtagsShow[3].totalCount || 0,
      hashtagsShow[4].totalCount || 0,
      hashtagsShow[5].totalCount || 0,
      sumOther,
    ],
    chart: {
      width: '50%',
      type: 'pie',
    },
    labels: [
      hashtagsShow[0]._id || '',
      hashtagsShow[1]._id || '',
      hashtagsShow[2]._id || '',
      hashtagsShow[3]._id || '',
      hashtagsShow[4]._id || '',
      hashtagsShow[5]._id || '',
      'Other',
    ],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + '%'];
      },
    },
    legend: {
      show: true,
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 700,
        options: {
          dataLabels: {
            formatter(val) {
              return [val.toFixed(1) + '%'];
            },
          },
          chart: {
            width: '100%',
          },
        },
      },
    ],
  };
  return options;
}
