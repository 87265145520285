// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from 'react';
import { UsersRolesCssClasses } from '../../UsersUIHelpers';

export function RolesColumnFormatter(cellContent, row, rowIndex, { roles }) {
  const rolesToInt = (role) => {
    switch (role) {
      case 'student':
        return 0;
      case 'teacher':
        return 1;
      default:
        break;
    }
  };

  return (
    <>
      <span
        className={`label label-dot label-${
          UsersRolesCssClasses[rolesToInt(row.role)]
        } mr-2`}
      ></span>
      &nbsp;
      <span
        className={`font-bold font-${
          UsersRolesCssClasses[rolesToInt(row.role)]
        }`}
      >
        {rolesToInt(row.role) === 0 ? roles.student : roles.teacher}
      </span>
    </>
  );
}
