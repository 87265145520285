/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UserRead } from './UserRead';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { URL } from '../../../../config/configuration';

export function UsersWidget({ className }) {
  const intl = useIntl();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  dayjs.extend(relativeTime);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/users/find/recent?page=1&per_page=5&sort=desc`)
      .then((response) => {
        const data = response.data.data;
        setUsers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Fetching users has error', error);
        setLoading(false);
      });
  }, []);

  function userClick(data) {
    setShow(!show);
    setCurrentUser(data);
  }

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label font-weight-bolder text-dark'>
            {intl.formatMessage({ id: 'DASHBOARD.WIDGET.USERS.TITLE' })}
          </span>
          <span className='text-muted mt-3 font-weight-bold font-size-sm'>
            {intl.formatMessage({ id: 'DASHBOARD.WIDGET.USERS.SUB_TITLE' })}
          </span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/user-management'>
            <button className='btn btn-primary font-weight-bolder font-size-sm mr-3'>
              {intl.formatMessage({ id: 'DASHBOARD.WIDGET.USERS.BUTTON' })}
            </button>
          </Link>
        </div>
      </div>
      {/* Body */}
      <div className='card-body pt-0 pb-3'>
        <div className='tab-content'>
          <div className='table-responsive'>
            <table className='table table-head-custom table-head-bg table-borderless table-vertical-center'>
              <tbody>
                {!loading ? (
                  users && users.length ? (
                    users.map((data) => (
                      <tr key={data._id}>
                        {/* profile & personal Id */}
                        <td className='pl-0 py-4' style={{ minWidth: '180px' }}>
                          <div className='d-flex align-items-center'>
                            <div
                              className={`symbol symbol-50 symbol-light-${
                                data.role === 'admin'
                                  ? 'info'
                                  : data.role === 'teacher'
                                  ? 'danger'
                                  : 'success'
                              } mr-4`}
                            >
                              <img
                                className='h-100 align-self-end'
                                src={`${URL.imagePrefix}/${data.avatar}`}
                                alt={`${data.firstName} ${data.lastName}`}
                              />
                            </div>
                            <div>
                              <p className='text-dark-75 font-weight-bolder mb-1 font-size-lg'>
                                {`${data.firstName} ${data.lastName}`}
                              </p>
                              <span className='text-muted font-weight-bold d-block'>
                                {data.personalId}
                              </span>
                            </div>
                          </div>
                        </td>

                        {/* Full name & role*/}
                        <td style={{ minWidth: '75px' }}>
                          <span className='text-muted font-weight-bold'>
                            <p
                              className={`label label-inline label-${
                                data.role === 'admin'
                                  ? 'info'
                                  : data.role === 'teacher'
                                  ? 'danger'
                                  : 'success'
                              } mb-0`}
                            >
                              {data.role === 'admin'
                                ? intl.formatMessage({
                                    id: 'DASHBOARD.WIDGET.USERS.ROLE.ADMIN',
                                  })
                                : data.role === 'teacher'
                                ? intl.formatMessage({
                                    id: 'DASHBOARD.WIDGET.USERS.ROLE.TEACHER',
                                  })
                                : intl.formatMessage({
                                    id: 'DASHBOARD.WIDGET.USERS.ROLE.STUDENT',
                                  })}
                            </p>
                          </span>
                        </td>

                        {/* major */}
                        {/* <td style={{ minWidth: '180px' }}>
                          <span className="text-dark-75 d-block font-size-lg">
                            {data.degreeProgram.major}
                          </span>
                        </td> */}

                        {/* Created time */}
                        <td style={{ minWidth: '130px' }}>
                          <span className='text-muted d-block font-size-sm'>
                            {dayjs(data.createdAt)
                              .locale('th')
                              .fromNow()}
                          </span>
                        </td>

                        {/* Action dialog */}
                        <td
                          className='pr-0 text-right'
                          style={{ minWidth: '80px' }}
                        >
                          <a
                            className='btn btn-light-success font-weight-bolder font-size-sm'
                            onClick={() => {
                              userClick(data);
                            }}
                          >
                            <i className='far fa-eye'></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <p>
                          {intl.formatMessage({
                            id: 'DASHBOARD.WIDGET.USERS.ERROR',
                          })}
                        </p>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td>
                      <p>
                        {intl.formatMessage({
                          id: 'DASHBOARD.WIDGET.USERS.LOADING',
                        })}
                      </p>
                    </td>
                  </tr>
                )}
                <UserRead
                  show={show}
                  user={currentUser}
                  onHide={() => {
                    setShow(false);
                    setCurrentUser(null);
                  }}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
