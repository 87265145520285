export default function setupAxios(axios, store) {
  // Set Api Base Url
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  // API REQUEST CALLS
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { accessToken },
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  // API RESPONSE
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        const {
          auth: { accessToken, refreshToken },
        } = store.getState();

        if (!accessToken || !refreshToken) {
          return;
        }

        originalRequest._retry = true;

        // Send refresh
        return axios
          .post('auth/refresh', { accessToken, refreshToken })
          .then((res) => {
            if (res.status === 201) {
              // 1) Put accessToken to Store
              store.dispatch({
                type: '[Refresh] Action',
                payload: { accessToken: res.data.accessToken },
              });
              // 2) Change Authorization header
              const {
                auth: { accessToken },
              } = store.getState();
              axios.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${accessToken}`;
              // 3) return originalRequest object with Axios.
              return axios(originalRequest);
            }
          })
          .catch((error) => {
            window.location.href = '/logout';
          });
      }

      return Promise.reject(error);
    }
  );
}
