export const UsersStatusCssClasses = ['danger', 'success', 'info', ''];
export const UsersGenderCssClasses = ['primary', 'danger', 'info', ''];
export const UsersRolesCssClasses = ['primary', 'danger', 'info', ''];
export const defaultSorted = [{ dataField: '_id', order: 'asc' }];
export const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];
export const initialFilter = {
  filter: {
    $expr: '', // firstName, lastName or personalId
    // degreeProgram: '',
    // gender: '',
    // status: '',
  },
  sortOrder: 'asc', // asc||desc
  sortField: '_id',
  pageNumber: 1,
  pageSize: 10,
};

export const GENDER = ['ชาย', 'หญิง', 'ไม่ระบุ'];
export const ROLE = ['admin', 'teacher', 'student'];
export const STATUS = ['active', 'inactive'];
export const USER_TITLE = {
  teacher: ['อ.', 'ดร.', 'ผศ.', 'ผศ. ดร.', 'รศ.', 'รศ. ดร.', 'ศ.', 'ศ. ดร.'],
  student: ['เด็กหญิง', 'เด็กชาย', 'นาย', 'นาง', 'นางสาว'],
};
