import { reject } from 'lodash';
import React from 'react';
import { useState } from 'react';
import { Col, Form, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { searchStudents } from '../../../_redux/students/studentsCrud';
import AsyncSelect from 'react-select/async';

export function StudentAppointForm({ saveStudent, actionsLoading, onHide }) {
  const intl = useIntl();
  const [inputType, setInputType] = useState(0);
  const [selectedValue, setSelectedValue] = useState(null);

  // Search Type
  const searchTypes = [
    {
      id: 0,
      name: 'StudentNo',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.TYPES.STUDENT_NO',
      }),
    },
    {
      id: 1,
      name: 'Email',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.TYPES.EMAIL',
      }),
    },
    {
      id: 2,
      name: 'firstName',
      text: intl.formatMessage({
        id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.TYPES.FIRSTNAME',
      }),
    },
  ];

  const handleTypeChange = (event) => {
    setInputType(event.target.value);
  };

  // handle handleSelectedChange
  const handleSelectedChange = (value) => {
    setSelectedValue(value);
  };

  // fetch data from api
  const loadOptions = (inputValue) => {
    return searchStudents(inputType, inputValue, 12)
      .then((response) => response.data.data)
      .catch((error) => reject(new Error(error.message)));
  };

  const renderStudentLabel = (student) => {
    return student.degreeProgram
      ? `${student.title}${student.firstName} ${student.lastName} • ${student.degreeProgram.name} ${student.degreeProgram.major}`
      : `${student.title}${student.firstName} ${student.lastName}`;
  };

  return (
    <>
      <Modal.Body>
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <Form>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="formGridType">
              <Form.Label>
                {intl.formatMessage({
                  id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.TYPES.LABEL',
                })}
              </Form.Label>
              <Form.Control
                as="select"
                onChange={(event) => handleTypeChange(event)}
                placeholder={intl.formatMessage({
                  id:
                    'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.TYPES.PLACEHOLDER',
                })}
              >
                {searchTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.text}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridQuery">
              <Form.Label>
                {intl.formatMessage({
                  id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.QUERY.LABEL',
                })}
              </Form.Label>
              {/* React Select: Async Searching Dropdown */}
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder={intl.formatMessage({
                  id:
                    'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.QUERY.PLACEHOLDER',
                })}
                isClearable
                getOptionLabel={(o) => renderStudentLabel(o)}
                getOptionValue={(o) => o._id}
                loadOptions={loadOptions}
                onChange={handleSelectedChange}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          {intl.formatMessage({
            id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.BUTTON.CANCEL',
          })}
        </button>
        <> </>
        <button
          type="submit"
          onClick={() => saveStudent(selectedValue)}
          className="btn btn-primary btn-elevate"
        >
          {intl.formatMessage({
            id: 'USER_MANAGEMENT.PAGES.EDIT.DIALOG.APPOINT.BUTTON.SAVE',
          })}
        </button>
      </Modal.Footer>
    </>
  );
}
