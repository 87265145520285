import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Input, Select } from '../../partials/controls';
import { GENDER, STATUS, USER_TITLE } from '../UsersUIHelpers';
import axios from 'axios';
import { useIntl } from 'react-intl';

export function UserEditForm({ user, btnRef, saveUser }) {
  const intl = useIntl();
  const [degreePrograms, setDegreePrograms] = useState([]);

  // Validation schema
  const UserEditSchema = Yup.object().shape({
    title: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.MIN2',
        })
      )
      .required(
        `${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.TITLE',
        })} ${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
        })}`
      ),
    firstName: Yup.string()
      .trim()
      .min(
        2,
        intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.MIN2',
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.MAX50',
        })
      )
      .required(
        `${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.FIRSTNAME',
        })} ${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
        })}`
      ),
    lastName: Yup.string()
      .trim()
      .min(
        2,
        intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.MIN2',
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.MAX50',
        })
      )
      .required(
        `${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.LASTNAME',
        })} ${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
        })}`
      ),
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.EMAIL',
        })
      )
      .required(
        `${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.EMAIL',
        })} ${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
        })}`
      ),
    gender: Yup.string()
      .oneOf(['ชาย', 'หญิง', 'ไม่ระบุ'])
      .required(
        `${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.GENDER',
        })} ${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
        })}`
      ),
    personalId: Yup.string()
      .trim()
      .min(
        2,
        intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.MIN2',
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.MAX50',
        })
      )
      .required(
        `${
          user.role === 'student'
            ? intl.formatMessage({
                id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.PERSONALID.STUDENT',
              })
            : intl.formatMessage({
                id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.PERSONALID.TEACHER',
              })
        } ${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
        })}`
      ),
    degreeProgram: Yup.string().required(
      `${intl.formatMessage({
        id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.DEGREEPROGRAM',
      })} ${intl.formatMessage({
        id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
      })}`
    ),
    role: Yup.string()
      .oneOf(['admin', 'teacher', 'student'])
      .required(
        `${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.ROLE',
        })} ${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
        })}`
      ),
    status: Yup.string()
      .oneOf(['active', 'inactive'])
      .required(
        `${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.STATUS',
        })} ${intl.formatMessage({
          id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.VALIDATE.REQUIRED',
        })}`
      ),
  });

  useEffect(() => {
    /**
     * Get initial Props
     * Props: Degree Program
     */
    axios.get('/degreeprogram').then(({ data: { data } }) => {
      setDegreePrograms([...data]);
    });
  }, []);

  const initialValues = {
    title: user.title,
    firstName: user.firstName,
    lastName: user.lastName,
    personalId: user.personalId,
    email: user.email,
    gender: user.gender,
    degreeProgram: user.degreeProgram._id,
    role: user.role,
    status: user.status,
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={UserEditSchema}
        onSubmit={(values) => {
          saveUser(values);
        }}
      >
        {({ handleSubmit }) => (
          <Form className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <Select
                  name="title"
                  label={intl.formatMessage({
                    id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.TITLE',
                  })}
                >
                  {user.role === 'student'
                    ? USER_TITLE.student.map((t) => (
                        <option key={t} value={t}>
                          {t}
                        </option>
                      ))
                    : USER_TITLE.teacher.map((t) => (
                        <option key={t} value={t}>
                          {t}
                        </option>
                      ))}
                </Select>
              </div>
              <div className="col-lg-5">
                <Field
                  name="firstName"
                  component={Input}
                  placeholder="Firstname"
                  label={intl.formatMessage({
                    id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.FIRSTNAME',
                  })}
                />
              </div>
              <div className="col-lg-5">
                <Field
                  name="lastName"
                  component={Input}
                  placeholder="Lastname"
                  label={intl.formatMessage({
                    id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.LASTNAME',
                  })}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="personalId"
                  component={Input}
                  placeholder="studentNo/teacherNo"
                  label={
                    user.role === 'student'
                      ? intl.formatMessage({
                          id:
                            'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.PERSONALID.STUDENT',
                        })
                      : intl.formatMessage({
                          id:
                            'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.PERSONALID.TEACHER',
                        })
                  }
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type="email"
                  name="email"
                  component={Input}
                  placeholder="Email address"
                  label={intl.formatMessage({
                    id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.EMAIL',
                  })}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <Select
                  name="gender"
                  label={intl.formatMessage({
                    id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.GENDER',
                  })}
                >
                  {GENDER.map((g, i) => (
                    <option value={g} key={i}>
                      {g}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-lg-3">
                <Select
                  name="degreeProgram"
                  label={intl.formatMessage({
                    id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.DEGREEPROGRAM',
                  })}
                >
                  {degreePrograms
                    ? degreePrograms.map((d) => (
                        <option
                          value={d._id}
                          key={d._id}
                        >{`${d.major}`}</option>
                      ))
                    : 'Loading...'}
                </Select>
              </div>
              <div className="col-lg-3">
                <Field
                  name="role"
                  component={Input}
                  placeholder="Role"
                  label={intl.formatMessage({
                    id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.ROLE',
                  })}
                  disabled
                />
              </div>
              <div className="col-lg-3">
                <Select
                  name="status"
                  label={intl.formatMessage({
                    id: 'USER_MANAGEMENT.PAGES.EDIT.FORM.FIELDS.STATUS',
                  })}
                >
                  {STATUS.map((s, i) => (
                    <option value={s} key={i}>
                      {s}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* begin:: submit button */}
            <button
              type="submit"
              style={{ display: 'none' }}
              ref={btnRef}
              onSubmit={() => handleSubmit()}
            ></button>
            {/* end:: submit button */}
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
