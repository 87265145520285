// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from 'react';
// import { UsersGenderCssClasses } from '../../UsersUIHelpers';

export function GenderColumnFormatter(cellContent, row, rowIndex, { gender }) {
  // const genderToInt = (gender) => {
  //   switch (gender) {
  //     case 'ชาย':
  //       return 0;
  //     case 'หญิง':
  //       return 1;
  //     case 'ไม่ระบุ':
  //       return 2;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      <span>
        {row.gender === 'ชาย'
          ? gender.male
          : row.gender === 'หญิง'
          ? gender.female
          : gender.no_gender}
      </span>
    </>
  );
}
